import { MxConstants } from '../../mxgraph';

export const EDGE_VIEW_STYLE_PARAMS: string[] = [
    // цвет связи
    MxConstants.STYLE_STROKECOLOR,
    // ширина связи
    MxConstants.STYLE_STROKEWIDTH,
    // стили связи обычный/orthogonalEdgeStyle/elbowEdgeStyle/isometricEdgeStyle/orthogonalEdgeStyle/entityRelationEdgeStyle
    MxConstants.STYLE_EDGE,
    // изогнутость связи - есть ли углы в форме линии
    MxConstants.STYLE_CURVED,
    // тип стрелки начала связи,
    MxConstants.STYLE_STARTARROW,
    // тип стрелки конца связи, если не нужен, то  присваиваем значение 'none'
    MxConstants.STYLE_ENDARROW,
    // размер шрифта
    MxConstants.STYLE_FONTSIZE,
    // цвет шрифта
    MxConstants.STYLE_FONTCOLOR,
    // тип шрифта
    MxConstants.STYLE_FONTFAMILY,
    // жирный/курсив/подчеркнутый шрифт
    MxConstants.STYLE_FONTSTYLE,
    // горизонтальный/вертикальный шрифт
    MxConstants.STYLE_HORIZONTAL,
    // выравнивание текста
    MxConstants.STYLE_ALIGN,
    // прерывистая
    MxConstants.STYLE_DASHED,
    // углы линий
    MxConstants.STYLE_ARCSIZE,
];

export const EDGE_START_END_POINTS_PARAMS: string[] = [
    // скругленность углов, если значение=0, то STYLE_ARCSIZE не работает и углы всегда прямые не скругленные
    MxConstants.STYLE_ROUNDED,
    // ортогональный вид, если значение=0, то линия связи проводится по прямой без дополнительных точек
    MxConstants.STYLE_ORTHOGONAL_LOOP.toString(),
    // определяет отступ до начала излома линии при присоединению к объекту
    MxConstants.STYLE_JETTY_SIZE,
    // определяет имя связи в виде html/text сейчас в приложении всегда html
    'html',
    // координата x выхода связи
    MxConstants.STYLE_EXIT_X,
    // координата y выхода связи
    MxConstants.STYLE_EXIT_Y,
    // поправка x выхода связи если выход не на портале
    MxConstants.STYLE_EXIT_DX,
    // поправка y выхода связи если выход не на портале
    MxConstants.STYLE_EXIT_DY,
    // при изменении геометрии связи определяет может ли линия 'накладываться' сама на себя или изменяется 'прямоугольниками'
    MxConstants.STYLE_EXIT_PERIMETER,
    // координата x входа связи
    MxConstants.STYLE_ENTRY_X,
    // координата y входа связи
    MxConstants.STYLE_ENTRY_Y,
    // поправка x входа связи если вход не на портале
    MxConstants.STYLE_ENTRY_DX,
    // поправка y входа связи если вход не на портале
    MxConstants.STYLE_ENTRY_DY,
    // при изменении геометрии связи определяет может ли линия 'накладываться' сама на себя или изменяется 'прямоугольниками'
    MxConstants.STYLE_ENTRY_PERIMETER,
    // направление текста в имени связи (слева-направо), так как у нас всегда html, направление текста задаются css
    MxConstants.STYLE_TEXT_DIRECTION,
];
