import { MxConstants } from '../mxgraph';
import { EDGE_VIEW_STYLE_PARAMS } from '../utils/constants/css.utils.constants';

export const getStyleValueByStyleKey = (key: string, style: string): string | undefined => {
    const styleValue = style?.split(';').reduce((acc, prop) => {
        const [property, propertyValue] = prop.split('=');
        if (property === key) {
            return propertyValue;
        }

        return acc;
    }, '');

    return styleValue || undefined;
};

export const inlineCssToStyleMap = (style: string): Map<string, string> | undefined => {
    const map = new Map();

    if (style) {
        style.split(';').forEach((el) => {
            if (!el.length) return;
            const [key, value] = el.split('=');
            map.set(key, value);
        });
    }

    let isLeastOneKeyOrValueUndefinedOrEmpty = false;

    Array.from(map).forEach((arr) => {
        const [key, value] = arr;
        if (!key || !value) isLeastOneKeyOrValueUndefinedOrEmpty = true;
    });

    if (map.size && !isLeastOneKeyOrValueUndefinedOrEmpty) return map;

    return undefined;
};

export const checkIsCorrectEdgeInlineCss = (inlineCss: string): boolean => {
    return !!inlineCss.match(/^[A-Za-z]+[#-]?[A-Za-z0-9]+[A-Za-z0-9 ._]+[A-Za-z0-9]+$/);
};

export const styleMapToInlineCss = (styleMap: Map<string, string>): string | undefined => {
    let isLeastOneKeyOrValueUndefinedOrEmpty = false;
    let isKeyOrValueContainIncorrectCharaters = false;

    const propertySeparator: string = '=';
    const declarationSeparator: string = ';';

    Array.from(styleMap).forEach((arr) => {
        const [key, value] = arr;
        if (!key || !value) isLeastOneKeyOrValueUndefinedOrEmpty = true;
        if (!checkIsCorrectEdgeInlineCss(key + value)) {
            isKeyOrValueContainIncorrectCharaters = true;
        }
    });

    if (isLeastOneKeyOrValueUndefinedOrEmpty || isKeyOrValueContainIncorrectCharaters) {
        return undefined;
    }

    const inlineStyle = Array.from(
        styleMap,
        ([property, propertyValue]) => property + propertySeparator + propertyValue + declarationSeparator,
    ).join('');

    return inlineStyle;
};

export const getShapeType = (style: string) => {
    let shapeType: string = 'shape';
    if (style.includes('strokeColor=none') || !style.includes('shape=')) {
        shapeType = 'text';
    }

    return shapeType;
};

export const getShapeId = (style: string) => {
    const styleProps: string[] = style.split(';');
    const symbolId = styleProps.find((prop) => prop.startsWith('shape=')) || '';

    return symbolId;
};

export const getNewEdgeStyle = (edgeTypeStyle: string | undefined, edgeStyle: string): string | undefined => {
    if (!edgeTypeStyle) return edgeStyle;
    const edgeTypeStyleMap: Map<string, string> | undefined = inlineCssToStyleMap(edgeTypeStyle);
    const edgeStyleMap: Map<string, string> | undefined = inlineCssToStyleMap(edgeStyle);

    if (!edgeTypeStyleMap) return undefined;

    const newEdgeStyleMap: Map<string, string> = edgeStyleMap || new Map();

    EDGE_VIEW_STYLE_PARAMS.forEach((style) => {
        const styleValue = edgeTypeStyleMap.get(style);
        if (styleValue) {
            newEdgeStyleMap.set(style, styleValue);
        } else {
            newEdgeStyleMap.delete(style);
        }
    });

    // нельзя просто удалить поле endArrow для того очистить конец стрелки
    // так как в графе ему присвоится дефолтное значение стрелки
    // поэтому принудительно присваеваем полю значение none
    const defaultEndArrow: string = edgeTypeStyleMap.get(MxConstants.STYLE_ENDARROW) || '';
    if (defaultEndArrow === 'none') {
        newEdgeStyleMap.set('endArrow', 'none');
    }

    const newEdgeStyleInline: string | undefined = styleMapToInlineCss(newEdgeStyleMap);

    return newEdgeStyleInline;
};
