import React, { FC, useState, ChangeEvent } from 'react';
import { UserDTO } from '../../../../serverapi/api';
import { SUPERADMIN_LOGIN } from '../../data/admintool';
import UserList from './UserList/UserList';
import UserListControls from './UserListControls';
import { StatusList } from './userManagment.constances';
import { Divider } from 'antd';
import theme from './UserManagmentUserList.scss';

export const UserManagmentUserList: FC = () => {
    const [status, setStatus] = useState<StatusList>(StatusList.allStatuses);
    const [searchInput, setSearchInput] = useState<string>('');
    const [checkedUsers, setCheckedUsers] = useState<UserDTO[]>([]);

    const handleChangeStatusFilter = (newStatus: StatusList) => {
        if (newStatus !== status) {
            setStatus(newStatus);
        }
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== searchInput) {
            setSearchInput(e.target.value.trim().toLowerCase());
        }
    };

    const handleSetCheckedUsers = (users: UserDTO[]): void => {
        setCheckedUsers(users.filter((user) => user.login !== SUPERADMIN_LOGIN));
    };

    return (
        <>
            <UserListControls
                checkedUsers={checkedUsers}
                handleChangeStatusFilter={handleChangeStatusFilter}
                handleSearch={handleSearch}
                searchInput={searchInput}
                setCheckedUsers={handleSetCheckedUsers}
                status={status}
            />
            <Divider className={theme.divider} />
            <UserList
                checkedUsers={checkedUsers}
                status={status}
                searchInput={searchInput}
                setCheckedUsers={handleSetCheckedUsers}
            />
        </>
    );
};
