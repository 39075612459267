import { defineMessages } from 'react-intl';

export default defineMessages({
    footerFilterTitle: {
        id: 'NavigatorStructure.footerTitle',
        defaultMessage: 'Фильтр:',
    },
    checkboxModel: {
        id: 'NavigatorStructure.checkboxModel',
        defaultMessage: 'Отображать модели',
    },
    checkboxObject: {
        id: 'NavigatorStructure.checkboxObject',
        defaultMessage: 'Отображать объекты',
    },
    showFiles: {
        id: 'NavigatorStructure.showFiles',
        defaultMessage: 'Отображать файлы',
    },
    showDeleted: {
        id: 'NavigatorStructure.showDeleted',
        defaultMessage: 'Отображать удаленные элементы',
    },
    properties: {
        id: 'Navigator.properties',
        defaultMessage: 'Свойства',
    },
    structure: {
        id: 'Navigator.structure',
        defaultMessage: 'Навигатор',
    },
    outline: {
        id: 'Navigator.outline',
        defaultMessage: 'Мини-карта',
    },
    serverAdd: {
        id: 'Navigator.serverAdd',
        defaultMessage: 'Добавить сервер',
    },
    symbols: {
        id: 'Navigator.symbols',
        defaultMessage: 'Символы',
    },
    readModeWarning: {
        id: 'Navigator.readModeWarning',
        defaultMessage: 'Вы находитесь в',
    },
    readModeBoldWarning: {
        id: 'Navigator.readModeBoldWarning',
        defaultMessage: 'режиме чтения',
    },
    editModeEnable: {
        id: 'Navigator.editModelEnable',
        defaultMessage: 'Нажмите, чтобы перейти в режим редактирования',
    },
    editModeForbiddenByProfile: {
        id: 'Navigator.editModeForbiddenByProfile',
        defaultMessage: 'Редактирование модели запрещено профилем пользователя',
    },
    yes: {
        id: 'Navigator.yes',
        defaultMessage: 'Да',
    },
    no: {
        id: 'Navigator.no',
        defaultMessage: 'Нет',
    },
    comments: {
        id: 'Navigator.comments',
        defaultMessage: 'Комментарии',
    },
    unknown: {
        id: 'Navigator.unknown',
        defaultMessage: 'Нет данных',
    },
    groupSymbolNotAvailable: {
        id: 'Navigator.groupSymbolNotAvailable',
        defaultMessage: 'Группировка символов не доступна, все символы в разных типах объектов',
    },
    groupSymbolOff: {
        id: 'Navigator.groupSymbolOff',
        defaultMessage: 'Выключить группировку символов',
    },
    groupSymbolOn: {
        id: 'Navigator.groupSymbolOn',
        defaultMessage: 'Включить группировку символов',
    },
    noInstance: {
        id: 'Navigator.noInstance',
        defaultMessage: 'нет экземпляров',
    },
    noAccess: {
        id: 'Navigator.noAccess',
        defaultMessage: 'нет доступа',
    },
    itemRemoved: {
        id: 'Navigator.itemRemoved',
        defaultMessage: 'Элемент удален',
    },
    noNameEdgeModelEntry: {
        id: 'Navigator.noNameEdgeModelEntry',
        defaultMessage: 'Вхождение связи безымянной модели',
    },
    toSearch: {
        id: 'Navigator.toSearch',
        defaultMessage: 'Искать',
    },
});
