import React, { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteUsers,
    setUsersBlock,
    openUserDataEditingTab,
    fetchAllUsersRequest,
} from '../../../../actions/users.actions';
import { UserDataEdititngTabMode } from '../../../../actions/users.actions.types';
import { newUserState } from '../../../../models/users';
import { UserDTO } from '../../../../serverapi/api';
import { DropdownButton } from '../../DropdownButton/DropdownButton';
import messages from '../messages/userManagment.messages';
import theme from './UserListControls.scss';
import { showConfirmationDialog } from './ConfirmationDialog';
import { TButtonAction } from '../../DropdownButton/DropdownButton.types';
import { Icon } from '../../../UIKit';
import lockIcon from '../../../../resources/icons/Lock.svg';
import unlockIcon from '../../../../resources/icons/Unlock.svg';
import addBtnIcon from '../../../../resources/icons/AddBtnWhite.svg';
import deleteIcon from '../../../../resources/icons/Deleted.svg';
import updateIcon from '../../../../resources/icons/Update.svg';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { Select } from '../../../UIKit/components/Select/Select.component';
import { StatusList } from './userManagment.constances';
import { SearchInput } from '../../../UIKit/components/Select/SearchInput.component';
import { Button } from '../../../UIKit/components/Button/Button.component';

type TUserListControls = {
    checkedUsers: UserDTO[];
    status: StatusList;
    searchInput: string;
    setCheckedUsers: (users: UserDTO[]) => void;
    handleChangeStatusFilter: (newStatus: string) => void;
    handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
};

const UserListControls: FC<TUserListControls> = (props) => {
    const { checkedUsers, status, searchInput, setCheckedUsers, handleSearch, handleChangeStatusFilter } = props;
    const intl = useIntl();
    const serverId = useSelector(MetaDataSelectors.getCurrentServerId);
    const dispatch = useDispatch();

    const okText = intl.formatMessage(messages.yes);
    const cancelText = intl.formatMessage(messages.no);
    const users: string[] = checkedUsers.map((user) => user.login);
    const isDisabledActions: boolean = !checkedUsers.length;

    const onAddClicked = () => {
        const userData: UserDTO = newUserState;
        dispatch(openUserDataEditingTab({ userData, mode: UserDataEdititngTabMode.ADD }));
    };

    const blockStatusHadler = (block: boolean) => {
        const onOk = () => {
            dispatch(
                setUsersBlock(
                    checkedUsers.map((user) => user.id),
                    block,
                ),
            );
            setCheckedUsers([]);
        };
        const action = block ? intl.formatMessage(messages.block) : intl.formatMessage(messages.unblock);
        const title = block ? intl.formatMessage(messages.blockTitle) : intl.formatMessage(messages.unblockTitle);
        showConfirmationDialog({ action, title, users, okText, cancelText, onOk });
    };

    const deleteUserHandler = () => {
        const onOk = () => {
            dispatch(deleteUsers(checkedUsers.map((user) => user.id)));
            setCheckedUsers([]);
        };
        const action = intl.formatMessage(messages.delete);
        const title = intl.formatMessage(messages.deleteTitle);
        showConfirmationDialog({ action, title, users, okText, cancelText, onOk });
    };

    const massUserActions: TButtonAction[] = [
        {
            title: intl.formatMessage(messages.block),
            onClick: () => blockStatusHadler(true),
            icon: <Icon className={theme.iconLockContainer} spriteSymbol={lockIcon} />,
            actionDisabled: checkedUsers.every((user) => user.blocked),
        },
        {
            title: intl.formatMessage(messages.unblock),
            onClick: () => blockStatusHadler(false),
            icon: <Icon className={theme.iconUnlockContainer} spriteSymbol={unlockIcon} />,
            actionDisabled: checkedUsers.every((user) => !user.blocked),
        },
    ];

    return (
        <div className={theme.searchDiv}>
            <div className={theme.searchInput}>
                <SearchInput
                    showSearch
                    originalTheme
                    dataTest="user-management-list-search-input"
                    searchValue={searchInput}
                    onSearch={handleSearch}
                />
            </div>

            <div data-test="userList_filter-status" className={theme.labelSelectContainer}>
                <Select
                    value={<span className={theme.selectText}>{intl.formatMessage(messages[status])}</span>}
                    wrapperClassName={theme.select}
                    onChange={handleChangeStatusFilter}
                >
                    {Object.keys(StatusList).map((key) => {
                        return (
                            <Select.Option
                                data-test={`userList_filter-status-${key}`}
                                value={StatusList[key]}
                                label={intl.formatMessage(messages[StatusList[key]])}
                            />
                        );
                    })}
                </Select>
            </div>
            <div className={theme.btnContainer}>
                <div className={theme.updateContainer}>
                    <Button
                        visualStyle={{ type: 'text' }}
                        onClick={() => dispatch(fetchAllUsersRequest({ serverId }))}
                        icon={updateIcon}
                    ></Button>
                </div>
                <DropdownButton disabled={isDisabledActions} actions={massUserActions} spriteSymbol={lockIcon} />
                <div className={theme.binContainer}>
                    <Button
                        dataTest={'user-managment_delete-user-button'}
                        visualStyle={{ type: 'text' }}
                        onClick={deleteUserHandler}
                        disabled={isDisabledActions}
                        icon={deleteIcon}
                    ></Button>
                </div>
                <div className={theme.addBtnContainer}>
                    <Button
                        dataTest={'user-managment_add-user-button'}
                        visualStyle="primary"
                        onClick={onAddClicked}
                        icon={addBtnIcon}
                        width={32}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserListControls;
