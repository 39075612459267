import { GridCell, GridHeader, GridLayout, InternationalString } from '@/serverapi/api';
import { Modal } from 'antd';
import { LocalesService } from '@/services/LocalesService';
import { MxCell, MxConstants, MxEvent, MxEventObject, MxPerimeter, MxPoint, MxPopupMenu, MxUtils } from 'MxGraph';
import { v4 as uuid } from 'uuid';
import messages from './GridDiagram.messages';
import { cellsTreeWalkerDown } from '@/mxgraph/ComplexSymbols/utils';
import { BPMMxConstants } from '@/mxgraph/bpmgraph.constants';
import {
    DEFAULT_CONTENT_COLUMN_HEIGHT,
    DEFAULT_CONTENT_COLUMN_WIDTH,
    SWIMLANE_NO_LABEL_FLAG,
} from './GridDiagram.constants';
import GridModelGraph from '../GridModelGraph';
import { IGridDiagram } from './GridDIagram.types';
import { NotificationType } from '@/models/notificationType';
import { showGridNotification, showRenameDialog } from './SideEffects';
import { EditorMode } from '@/models/editorMode';
import { CustomMxEvent } from '@/sagas/editor.saga.constants';
import { SymbolType } from '@/models/Symbols.constants';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import { EdgeInstanceImpl } from '@/models/bpm/bpm-model-impl';
import { MessageDescriptor } from 'react-intl';

export class GridDiagram implements IGridDiagram {
    static titleHeight: number = 0;
    static defaultHeaderHeight: number = 20;
    static defaultContentColumnWidth: number = DEFAULT_CONTENT_COLUMN_WIDTH;
    static defaultContentRowHeight: number = DEFAULT_CONTENT_COLUMN_HEIGHT;
    static swimlaneNoLabelFlag = SWIMLANE_NO_LABEL_FLAG;
    static cellsInitialStyle = 'movable=0;deletable=0;';
    static autoEdgeStyleName = 'autoEdge';

    protected headHorizontalStyle = 'headHorizontal';
    protected headVerticalStyle = 'headVertical';
    isRenderEdges: boolean = false;

    invisibleEdges = true;
    protected graph: GridModelGraph;

    constructor(graph: GridModelGraph, gridLayout: GridLayout, name = '') {
        this.graph = graph;
        this.initStyleForDiagram();
        this.render(gridLayout, name);
        this.addKeyBindings();
        this.addLayout();
    }

    protected applySettings() {
        const { graph } = this;
        graph.graphHandler.setRemoveCellsFromParent(false);
        graph.setDropEnabled(true);
        graph.setSplitEnabled(false);
    }

    protected addLayout() {}

    renderEdges(mxCell) {}

    connectTableCellChildren({
        sourceCell,
        targetGridCell,
        title,
        getEdgeTypeId,
        style,
        reverseDirection = false,
    }: {
        sourceCell: MxCell;
        targetGridCell: GridCell;
        title?: MessageDescriptor;
        getEdgeTypeId: (source: MxCell, target: MxCell) => string | undefined;
        style?: string;
        reverseDirection?: boolean;
    }) {
        try {
            const table = this.graph.getTable(sourceCell);
            if (!table) {
                return;
            }

            let targetCell;
            cellsTreeWalkerDown([table], (cell: MxCell) => {
                if (cell.getId() === targetGridCell.id) {
                    targetCell = cell;
                    return;
                }
            });

            if (!targetCell) {
                return [];
            }

            const targetTableCells = (targetCell.children || []).filter(this.isEdgeSupportedCell);
            const parent = this.graph.getDefaultParent();
            const intl = LocalesService.useIntl();
            const invisible = this.invisibleEdges;

            const edges: MxCell[] = [];
            targetTableCells.forEach((targetTableCell) => {
                const [source, target] = reverseDirection
                    ? [targetTableCell, sourceCell]
                    : [sourceCell, targetTableCell];
                const id = uuid();
                const edgeTypeId = getEdgeTypeId(source, target);
                if (!edgeTypeId) {
                    console.log(`empty type symbolID from: ${source.getId()} to: ${target.getId()}`);

                    return;
                }
                const edgeValue = new EdgeInstanceImpl({
                    id,
                    source: source.id,
                    target: target.id,
                    name: title ? intl.formatMessage(title) : '',
                    invisible,
                    style: `${GridDiagram.autoEdgeStyleName || ''};${style || ''};`,
                    edgeTypeId,
                });

                const edge = this.graph.insertEdge(parent, id, edgeValue, source, target);
                edge.setVisible(!invisible);

                edge.setStyle(`${edge.getStyle()}${style};${GridDiagram.autoEdgeStyleName}`);
                edges.push(edge);
            });

            return edges;
        } catch (e) {
            console.error(e);

            return [];
        }
    }

    clearEdges(source: MxCell) {
        const sourceEdges = (source?.edges || []).filter((edge) => {
            return edge.getStyle().includes(GridDiagram.autoEdgeStyleName);
        });

        if (sourceEdges.length) {
            this.graph.removeCells(sourceEdges);
        }
    }

    isEdgeSupportedCell(cell: MxCell): boolean {
        return cell.getValue()?.type === 'object';
    }

    protected addKeyBindings() {
        const { graph } = this;

        graph.getModel().addListener(CustomMxEvent.DROP_ELEMENT, (sender, event: MxEventObject) => {
            const cellsBeforeDropElements: MxCell[] = (event.properties.target as (MxCell | undefined))?.children || [];
            const cellsIdsBeforeDropElements: String[] = cellsBeforeDropElements
                .map(c => c.getValue()?.id)
                .filter(c => c);

            requestAnimationFrame(() => {
                // поиск новых ячеек. которые добавлены в клетку
                const cellsAfterDropElements: MxCell[] = 
                    ((event.properties.target as (MxCell | undefined))?.children || [])
                    .filter(c => !cellsIdsBeforeDropElements.includes(c.getValue()?.id));

                cellsAfterDropElements.forEach(c => this.renderEdges(c))
            });
        });

        const doubleClickListener = (sender, event: MxEventObject) => {
            const targetCell = event.getProperty('cell');
            if (this.graph.mode !== EditorMode.Edit) {
                event.consume();

                return;
            }
            if (this.graph.isTable(targetCell) || this.graph.isTableCell(targetCell)) {
                event.consume();
            }

            const style = targetCell.getStyle();
            if (style.includes(this.headHorizontalStyle) || style.includes(this.headVerticalStyle)) {
                this.showRenameDialog(targetCell, (value) => {
                    targetCell.setValue(value);
                    this.graph.refresh();
                });
            }
        };
        graph.addListener(MxEvent.DOUBLE_CLICK, doubleClickListener);
    }

    showRenameDialog(cell: MxCell, callback) {
        showRenameDialog(cell.getValue(), callback);
    }

    protected getNewRowTitle(): string {
        return 'new row';
    }

    protected getNewColumnTitle(): string {
        return 'new column';
    }

    public serialize(): GridLayout {
        const result: GridLayout = {
            cells: [],
            rows: [],
            columns: [],
        };

        const getIntenationalString = (text: string) => {
            const locale = LocalesService.getLocale();

            return { [locale]: text };
        };

        const serializeCell = (cell: MxCell, r: number, c: number) => {
            if (r == 0 && c == 0) {
                result.columnHeaderHeight = cell.getGeometry().height;
                result.rowHeaderWidth = cell.getGeometry().width;

                return;
            }

            if (r === 0) {
                result.columns.push({
                    id: cell.getId(),
                    name: getIntenationalString(cell.getValue()),
                    headerSize: cell.getGeometry().width,
                });

                return;
            }

            if (c === 0) {
                result.rows.push({
                    id: cell.getId(),
                    name: getIntenationalString(cell.getValue()),
                    headerSize: cell.getGeometry().height,
                });

                return;
            }

            if (!result.columns[c - 1] || !result.rows[r - 1]) {
                return;
            }

            const { id: rowId = uuid() } = result.rows[r - 1];
            const { id: columnId = uuid() } = result.columns[c - 1];

            result.cells.push({
                id: cell.getId(),
                rowId,
                columnId,
            });
        };

        const edgeFilter = (c: MxCell) => !c.edge;
        const model = this.graph.getModel();
        const { root } = this.graph.getModel();
        const [table] = root.children[0].children;

        model
            .getChildCells(table)
            .filter(edgeFilter)
            .forEach((row, r) => {
                model
                    .getChildCells(row)
                    .filter(edgeFilter)
                    .forEach((cell, c) => {
                        serializeCell(cell, r, c);
                    });
            });

        return result;
    }

    public isGridValidTarget({
        target,
        symbolId,
        source,
    }: {
        source?: MxCell;
        target: MxCell;
        symbolId?: string;
    }): boolean {
        const { type } = source?.getValue() || {};
        const avaialbleSymbolTypes = [
            SymbolType.SHAPE,
            SymbolType.COMMENT,
            SymbolType.LABEL,
            PictureSymbolConstants.PICTURE_SYMBOL_ID,
        ];
        if (avaialbleSymbolTypes.includes(type) || avaialbleSymbolTypes.includes(symbolId || '')) {
            return true;
        }

        return false;
    }

    private render(gridLayout: GridLayout, name: string) {
        const parent = this.graph.getDefaultParent();
        const { columns, rows } = gridLayout;
        const intl = LocalesService.useIntl();
        const title = GridDiagram.titleHeight ? name || intl.formatMessage(messages.defaultModelName) : null;

        const table = this.graph.createTable(
            rows.length + 1,
            columns.length + 1,
            GridDiagram.defaultContentColumnWidth,
            GridDiagram.defaultContentRowHeight,
            title,
            GridDiagram.titleHeight,
            null,
            null,
            null,
        );

        this.setTableHeaderSizes(table, gridLayout);
        this.setTableContent(table);
        this.tableBinding(table, gridLayout);

        cellsTreeWalkerDown([table], (cell: MxCell) => {
            cell.setStyle(`${cell.getStyle()};${GridDiagram.cellsInitialStyle};resizable=0;`);
        });
        const [root] = this.graph.importCellsWithIds([table], new MxPoint(0, 0), parent);
        const [tableRoot] = root;

        this.afterRender(tableRoot);

        return tableRoot;
    }

    protected afterRender(tableRoot: MxCell) {}

    private setTableHeaderSizes(table: MxCell, gridLayout: GridLayout) {
        const { rowHeaderWidth = 20, columnHeaderHeight = 20, columns, rows } = gridLayout;
        const rowCell = table.children[0].children[0];
        const { width, height } = rowCell.getGeometry();
        this.graph.setTableColumnWidth(rowCell, rowHeaderWidth - width, true);
        this.graph.setTableRowHeight(rowCell, columnHeaderHeight - height, true);

        table.children.forEach((rowCell, i) => {
            if (i === 0) {
                return;
            }

            const { height } = rowCell.getGeometry();
            const { headerSize: columnHeight = 200 } = rows[i - 1] || {};

            this.graph.setTableRowHeight(rowCell, columnHeight - height, true);
            rowCell.children.forEach((colCell, j) => {
                const { width } = colCell.getGeometry();
                if (j === 0) {
                    return;
                }
                const { headerSize: columnWidth = 200 } = columns[j - 1] || {};

                this.graph.setTableColumnWidth(colCell, columnWidth - width, true);
            });
        });
    }

    private getInternetionalText(internationalString: InternationalString | undefined) {
        if (!internationalString) {
            return '';
        }

        const locale = LocalesService.getLocale();

        return internationalString[locale];
    }

    private setTableContent(table: MxCell) {
        for (let i = 1; i < table.children.length; i++) {
            const row = table.children[i];
            for (let j = 1; j < row.children.length; j++) {
                const cell = row.children[j] || [];
                cell.setStyle(`${cell.getStyle()};swimlane;${GridDiagram.swimlaneNoLabelFlag};startSize=0;`);
            }
        }
    }

    private tableBinding(root: MxCell, gridLayout: GridLayout) {
        const { columns, rows, cells } = gridLayout;

        const setHeadProperty = (headCell: MxCell, gridHeader: GridHeader) => {
            if (!gridHeader) {
                return;
            }
            const { id, name } = gridHeader;
            const text = this.getInternetionalText(name);

            headCell.setId(id || uuid());

            headCell.setValue(text);
        };

        const setContentProperty = (contentCell: MxCell, r: number, c: number) => {
            if (!columns[c - 1] || !rows[r - 1]) {
                console.error('column or row not found');

                return;
            }
            const { id: columnId } = columns[c - 1];
            const { id: rowId } = rows[r - 1];

            const gridCell = cells.find((gridCell) => {
                return gridCell.columnId === columnId && gridCell.rowId === rowId;
            });

            const id = gridCell?.id || uuid();

            contentCell.setId(id);
        };

        const bindCell = (cell: MxCell, r: number, c: number) => {
            if (r === 0 && c === 0) {
                return;
            }

            if (r === 0) {
                setHeadProperty(cell, columns[c - 1]);
                const style = `${cell.getStyle()};${this.headHorizontalStyle};`;
                cell.setStyle(style);

                return;
            }

            if (c === 0) {
                setHeadProperty(cell, rows[r - 1]);
                const style = `${cell.getStyle()};${this.headVerticalStyle};`;
                cell.setStyle(style);

                return;
            }

            setContentProperty(cell, r, c);
        };

        root.children.forEach((row, r) => {
            row.setId(uuid());
            row.children.forEach((cell, c) => {
                bindCell(cell, r, c);
            });
        });
        root.setId(uuid());
    }

    public loadPopupMenu(menu: MxPopupMenu, cell: MxCell, disabled: boolean = false) {
        const intl = LocalesService.useIntl();
        const target = this.graph.getLastEventTableTargetCell();
        const isRowSelected = (tableRow) => {
            const style = tableRow.getStyle();

            return style.includes('shape=tableRow') || style.includes('shape=partialRectangle');
        };
        const addPopupItem = (text, handler, enabled = true) => {
            menu.addItem(text, null, handler, null, '', !disabled && enabled);
        };

        addPopupItem(intl.formatMessage(messages.addRow), () => {
            this.addRow(cell);
        });
        addPopupItem(
            intl.formatMessage(messages.removeRow),
            () => {
                this.deleteRow(target);
            },
            isRowSelected(cell),
        );
        addPopupItem(intl.formatMessage(messages.addColumn), () => {
            this.addColumn(target);
        });
        addPopupItem(
            intl.formatMessage(messages.removeColumn),
            () => {
                this.deleteColumn(target);
            },
            isRowSelected(cell),
        );
    }

    protected addRow(cell: MxCell, title?: string) {
        const row = this.graph.insertTableRow(cell);
        if (!row) {
            return;
        }
        this.setCellDefaultValue(row);
        if (row?.children.length) {
            const [headerCell]: MxCell[] = row.children;

            const rowTitle = title || this.getNewRowTitle();

            headerCell.setValue(rowTitle);
            headerCell.setStyle(`${headerCell.getStyle()};${this.headVerticalStyle};`);
        }
        this.graph.refresh();
    }

    protected addColumn(cell: MxCell) {
        const columnCells = this.graph.insertTableColumn(cell, false);
        this.setCellDefaultValue(cell);
        if (columnCells?.length) {
            const [headerCell]: MxCell[] = columnCells;
            const rowTitle = this.getNewColumnTitle();

            headerCell?.setValue(rowTitle);
            headerCell?.setStyle(`${headerCell?.getStyle()};${this.headHorizontalStyle};`);
        }
        this.graph.refresh();
    }

    protected deleteRow(cell: MxCell) {
        if (this.graph.indexOfRow(cell) < 1) {
            showGridNotification(NotificationType.PSD_DELETE_FIRST_ROW_ERROR);

            return;
        }

        const rows = this.graph.getTableRows(cell);

        if (rows.length <= 2) {
            showGridNotification(NotificationType.EPC_DELETE_LAST_ROW_ERROR);
            return;
        }

        const intl = LocalesService.useIntl();
        Modal.confirm({
            title: intl.formatMessage(messages.removeRow),
            content: intl.formatMessage(messages.removeRowConfirmText),
            okText: intl.formatMessage(messages.remove),
            cancelText: intl.formatMessage(messages.cancel),
            onOk: () => {
                this.graph.deleteTableRow(cell);
            },
        });
    }

    protected deleteColumn(cell: MxCell) {
        if (!cell) {
            return;
        }

        console.log('delete column super', this.graph.indexOfColumn(cell));
        const columns = this.graph.getTableColumns(cell);
        if (columns.length <= 2) {
            showGridNotification(NotificationType.EPC_DELETE_LAST_COLUMN_ERROR);
            return;
        }
        if (this.graph.indexOfColumn(cell) < 1) {
            showGridNotification(NotificationType.PSD_DELETE_FIRST_COLUMN_ERROR);

            return;
        }
        const intl = LocalesService.useIntl();
        Modal.confirm({
            title: intl.formatMessage(messages.removeColumn),
            content: intl.formatMessage(messages.removeColumnConfirmText),
            okText: intl.formatMessage(messages.remove),
            cancelText: intl.formatMessage(messages.cancel),
            onOk: () => {
                this.graph.deleteTableColumn(cell);
            },
        });
    }

    private setCellDefaultValue(cell: MxCell) {
        const model = this.graph.getModel();

        cellsTreeWalkerDown([cell], (childCell: MxCell) => {
            if (!childCell.getValue()) {
                childCell.setValue('');
                childCell.children = childCell.children || [];
            }
            if (!!Number(childCell.getId())) {
                const id = uuid();
                childCell.setId(id);
                model.cells[id] = childCell;
            }
        });
    }

    private initStyleForDiagram() {
        const style1 = {};
        style1[MxConstants.STYLE_SHAPE] = MxConstants.SHAPE_SWIMLANE;
        style1[MxConstants.STYLE_PERIMETER] = MxPerimeter.RectanglePerimeter;
        style1[MxConstants.STYLE_VERTICAL_ALIGN] = 'middle';
        style1[MxConstants.STYLE_SWIMLANE_FILLCOLOR] = 'transparent';
        style1[MxConstants.STYLE_FONTSIZE] = 11;
        style1[MxConstants.STYLE_STARTSIZE] = 22;
        style1[MxConstants.STYLE_HORIZONTAL] = true;
        style1[MxConstants.STYLE_FONTCOLOR] = 'black';
        style1[MxConstants.STYLE_STROKECOLOR] = 'black';
        style1[MxConstants.STYLE_MOVABLE] = 0;
        style1[MxConstants.STYLE_DELETABLE] = 0;
        style1[MxConstants.STYLE_RESIZABLE] = 0;
        style1[MxConstants.STYLE_FOLDABLE] = 0;

        delete style1[MxConstants.STYLE_FILLCOLOR];
        this.graph.getStylesheet().putCellStyle('swimlane', style1);

        const swimlaneNoLabel = MxUtils.clone(style1);
        swimlaneNoLabel[MxConstants.STYLE_NOLABEL] = 1;
        swimlaneNoLabel[MxConstants.STYLE_EDITABLE] = 0;
        swimlaneNoLabel[BPMMxConstants.STYLE_SELECTABLE] = 0;
        swimlaneNoLabel[MxConstants.STYLE_STARTSIZE] = 0;
        swimlaneNoLabel[MxConstants.STYLE_MOVABLE] = 0;
        swimlaneNoLabel[MxConstants.STYLE_RESIZABLE] = 0;
        swimlaneNoLabel[MxConstants.STYLE_DELETABLE] = 1;

        this.graph.getStylesheet().putCellStyle('swimlaneNoLabel', swimlaneNoLabel);

        const styleHeadVertical = MxUtils.clone(style1);
        styleHeadVertical[MxConstants.STYLE_SHAPE] = MxConstants.SHAPE_RECTANGLE;
        styleHeadVertical[MxConstants.STYLE_HORIZONTAL] = false;
        styleHeadVertical[MxConstants.STYLE_MOVABLE] = 0;
        styleHeadVertical[MxConstants.STYLE_RESIZABLE] = 0;
        styleHeadVertical[MxConstants.STYLE_DELETABLE] = 1;
        styleHeadVertical[BPMMxConstants.STYLE_SELECTABLE] = 0;
        this.graph.getStylesheet().putCellStyle('headVertical', styleHeadVertical);

        const styleHeadHorizontal = MxUtils.clone(styleHeadVertical);
        styleHeadHorizontal[MxConstants.STYLE_HORIZONTAL] = true;
        this.graph.getStylesheet().putCellStyle('headHorizontal', styleHeadHorizontal);
    }
}
