import React from 'react';
import theme from './userAccount.scss';
import { useDispatch, useSelector } from 'react-redux';
import { UserAccountSelectors } from '../../selectors/userAccount.selectors';
import { useIntl } from 'react-intl';
import messages from './userAccount.messages';
import { openDialog } from '../../actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { InfoButton } from '../UIKit/components/InfoButton/InfoButton.component';

export const UserAccountSecurity = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const timeChangePassword: string = useSelector(UserAccountSelectors.getTimeChangePassword);
    const passwordChangePermission: boolean = !!useSelector(UserAccountSelectors.getPasswordChangePermission);

     const handleClick = () => {
        dispatch(openDialog(DialogType.CHANGE_PASSWORD_DIALOG));
    };

    return (
        <div className={theme.accountContainer}>
            <div className={theme.security}>{intl.formatMessage(messages.securityTab)}</div>
            <div className={theme.securityNote}>{intl.formatMessage(messages.securityNote)}</div>
            <InfoButton
                titleText={intl.formatMessage(messages.changePassword)}
                infoText={`${intl.formatMessage(messages.lastUpDate)} ${timeChangePassword}`}
                onClick={handleClick}
                passwordChangePermission={passwordChangePermission}
            />
        </div>
    );
};
