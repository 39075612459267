import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import messages from './WikiLinkDialog.messages';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { TFormValues, TLinkDialogProps } from './WikiLinkDialog.types';
import theme from './WikiLinkDialog.scss';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { TreeNode, TTreeEntityState } from '../../../models/tree.types';
import { openChooseTreeNode, treeItemSelect, treeItemsClearSelection } from '../../../actions/tree.actions';
import { NodeId } from '../../../serverapi/api';
import { closeDialog, openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { prepareUrl } from '../../UIKit/components/EditableText/editableText.utils';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import iconSubmenu from '../../../resources/icons/ic-sidemenu-navigator.svg';
import { TreeItemType } from '../../Tree/models/tree';
import { TWikiLink } from '@/models/tab.types';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { ExternalLinkBLLService } from '@/services/bll/ExternalLinkBLLService';
import { isInternalLink } from '@/utils/url.utils';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TLinkDialogAllProps = WrappedComponentProps & TLinkDialogProps;

const internalLinkValidator = (_, value: string) => {
    if (isInternalLink(value)) {
        return Promise.resolve();
    }

    return Promise.reject();
};

const LinkDialogComponent = (props: TLinkDialogAllProps) => {
    const { intl } = props;
    const dispatch = useDispatch();
    const selected: TreeNode | undefined = useSelector(SelectedNodesSelector.getNode());
    const activeScheme = useSelector(TabsSelectors.getActiveTab);
    const node: TTreeEntityState | undefined = useSelector(TreeSelectors.itemById(selected?.nodeId));
    const workspaceId: NodeId | undefined = activeScheme && activeScheme.nodeId;
    const [form] = Form.useForm<TFormValues>();
    const emptyTreeNode = {
        nodeId: {
            serverId: '',
            id: '',
            repositoryId: '',
        },
        name: '',
        type: '' as TreeItemType,
        hasChildren: false,
        countChildren: 0,
    };

    useEffect(() => {
        const url = selected?.name
            ? ExternalLinkBLLService.createExternalLink(
                  window.location.origin,
                  selected.type,
                  selected.nodeId.repositoryId,
                  selected.nodeId.id,
              )
            : '';

        form.setFieldsValue({ url });
    }, [selected]);

    useEffect(() => {
        dispatch(treeItemSelect(emptyTreeNode));
        form.resetFields();
        form.setFieldsValue({ text: props.text || '', url: props.url || '' });
    }, [form]);

    const onClose = () => {
        dispatch(closeDialog(DialogType.LINK_DIALOG));
        dispatch(treeItemsClearSelection());
    };

    const onSubmit = (workspaceNodeId: NodeId | undefined, link: TWikiLink) => {
        if (workspaceNodeId) {
            props.onSubmit(link);

            onClose();
            dispatch(treeItemSelect(emptyTreeNode));
        }
    };

    const handleSubmit = () => {
        if (form) {
            form.validateFields().then((formValues: TFormValues) => {
                onSubmit(workspaceId, {
                    url: prepareUrl(formValues, node),
                    text: formValues.text,
                } as TWikiLink);
            });
        }
    };
    const openChooseTreeNodeDialog = (serverId: string, repositoryId: string) => {
        dispatch(
            openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                serverId,
                repositoryId,
                onSubmit: (nodeId: NodeId) => {
                    dispatch(openChooseTreeNode(nodeId));
                },
                disableContextMenu: true,
            }),
        );
    };

    const linkNodeButton = !props.external ? (
        <div className={theme.iconContainer} data-test="wiki-toolbar-group_add-link-button_add-tree-node">
            <Icon
                spriteSymbol={iconSubmenu}
                onClick={() => {
                    if (workspaceId) {
                        openChooseTreeNodeDialog(workspaceId.serverId, workspaceId.repositoryId);
                    }
                }}
            />
        </div>
    ) : null;

    const children = (
        <Form form={form}>
            <Form.Item
                label={intl.formatMessage(messages.linkUrlLabel)}
                name="url"
                rules={[
                    { required: true, message: intl.formatMessage(messages.formValidationMessageRequiredUrl) },
                    {
                        validator: !props.external ? internalLinkValidator : undefined,
                        message: intl.formatMessage(messages.formValidationMessageInternalUrl),
                    },
                ]}
            >
                <Input addonAfter={linkNodeButton} data-test="wiki-toolbar-group_add-link-button_add-url-link" />
            </Form.Item>

            <Form.Item
                label={intl.formatMessage(messages.linkTextLabel)}
                name="text"
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages.formValidationMessageRequiredLabel),
                    },
                ]}
            >
                <Input data-test="wiki-toolbar-group_add-link-button_add-text-link" />
            </Form.Item>
        </Form>
    );

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.formDeclineButton),
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.formConfirmButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.formName)}
            open
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
        >
            {children}
        </Dialog>
    );
};

const LinkDialogWithIntl = injectIntl(LinkDialogComponent);

export { LinkDialogWithIntl as LinkDialog };
