import { Checkbox, Dropdown } from 'antd';
import React, { useState, useCallback, FC } from 'react';
import { TreeItemType } from '../../../../Tree/models/tree';
import messages from '../../../messages/Navigator.messages';
import { useIntl } from 'react-intl';
import theme from '../NavigatorStructure.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getFiltersApplied, getShowDeletedObjectsFilter } from '../../../../../selectors/tree.selectors';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { setShowDeletedObjectsFilterRequest, treeFilter } from '../../../../../actions/tree.actions';
import { NavigatorPanelButton } from '../../NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { DropdownIcon } from '../../NavigatorPanel/icons/DropdownIcon.component';
import { isUserHasAccess } from '@/selectors/authorization.selectors';
import userAccessTypes from '../../../../../models/userAccessRightTypes';
import { UserDTOAccessesEnum } from '@/serverapi/api';

export const TreeFiltersDropdown: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [open, setOpen] = useState<boolean>(false);

    const appliedFilters = useSelector(getFiltersApplied);
    const showDeletedObjectsFilter = useSelector(getShowDeletedObjectsFilter);
    const isShowDeletedObjectsAccessible =
        useSelector(isUserHasAccess(userAccessTypes.SHOW_DELETED_OBJECT as UserDTOAccessesEnum)) ||
        useSelector(isUserHasAccess(userAccessTypes.DB_AUDIT as UserDTOAccessesEnum));

    const toggleOpen = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    const handlerFilterChange = (event: CheckboxChangeEvent) => {
        const {
            target: { checked, value },
        } = event;

        let filterTypes: TreeItemType[] | null = null;

        switch (value) {
            case TreeItemType.Model: {
                filterTypes = [
                    TreeItemType.Model,
                    TreeItemType.Wiki,
                    TreeItemType.Matrix,
                    TreeItemType.SimulationModeling,
                    TreeItemType.Spreadsheet,
                ];
                break;
            }
            case TreeItemType.ObjectDefinition: {
                filterTypes = [TreeItemType.ObjectDefinition];
                break;
            }
            case TreeItemType.File: {
                filterTypes = [TreeItemType.File];
                break;
            }
            default: {
                console.warn('Unsupported filter');
            }
        }

        if (filterTypes) dispatch(treeFilter(checked, filterTypes));
    };

    const handleShowDeletedChange = (event: CheckboxChangeEvent) => {
        const {
            target: { value },
        } = event;

        dispatch(setShowDeletedObjectsFilterRequest(!value));
    };

    const items = [
        {
            key: TreeItemType.Model as string,
            label: (
                <Checkbox
                    className={theme.checkbox}
                    onChange={handlerFilterChange}
                    value={TreeItemType.Model}
                    defaultChecked
                    checked={!appliedFilters.includes(TreeItemType.Model)}
                    data-test="navigator-structure_toggle-model-view-checkbox"
                >
                    {intl.formatMessage(messages.checkboxModel)}
                </Checkbox>
            ),
        },
        {
            key: TreeItemType.ObjectDefinition as string,
            label: (
                <Checkbox
                    className={theme.checkbox}
                    onChange={handlerFilterChange}
                    value={TreeItemType.ObjectDefinition}
                    defaultChecked={false}
                    checked={!appliedFilters.includes(TreeItemType.ObjectDefinition)}
                    data-test="navigator-structure_toggle-object-view-checkbox"
                >
                    {intl.formatMessage(messages.checkboxObject)}
                </Checkbox>
            ),
        },
        {
            key: TreeItemType.File as string,
            label: (
                <Checkbox
                    className={theme.checkbox}
                    onChange={handlerFilterChange}
                    value={TreeItemType.File}
                    defaultChecked={true}
                    checked={!appliedFilters.includes(TreeItemType.File)}
                    data-test="navigator-structure_toggle-file-view-checkbox"
                >
                    {intl.formatMessage(messages.showFiles)}
                </Checkbox>
            ),
        },
    ];

    if (isShowDeletedObjectsAccessible) {
        items.push({
            key: 'showDeleted',
            label: (
                <Checkbox
                    className={theme.checkbox}
                    onChange={handleShowDeletedChange}
                    value={showDeletedObjectsFilter}
                    defaultChecked={false}
                    checked={showDeletedObjectsFilter}
                    data-test="navigator-structure_toggle-object-view-deleted-checkbox"
                >
                    {intl.formatMessage(messages.showDeleted)}
                </Checkbox>
            ),
        });
    }

    return (
        <Dropdown destroyPopupOnHide onOpenChange={toggleOpen} open={open} menu={{ items }} trigger={['click']}>
            <NavigatorPanelButton IconComponent={DropdownIcon} active={open} />
        </Dropdown>
    );
};
