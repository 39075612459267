import * as React from 'react';
import { injectIntl } from 'react-intl';
import { ScriptNode, ScriptNodeLanguageEnum } from '../../../../serverapi/api';
import theme from './ScriptEditor.component.scss';
import { changes } from '../../../../utils/object';
import { debounce } from 'lodash-es';
import { CommentsPanelContainer } from '../../../Comments/containers/CommentsPanel.container';
import SplitPane from 'react-split-pane';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import electron, { remote } from '../../../../electron';
import { EditorMode } from '../../../../models/editorMode';
import { LOCK_TIMEOUT } from '../../../../utils/consts';
import { TScriptEditorProps } from '../ScriptEditor.types';
import { Editor, loader } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { LocalesService } from '@/services/LocalesService';

class ScriptEditor extends React.Component<TScriptEditorProps> {
    state = {
        script: {} as ScriptNode,
    };

    debounceOnChange: Function;

    constructor(props: TScriptEditorProps) {
        super(props);

        const { script } = this.props;
        this.state = {
            script: { ...script },
        };
        this.onChangeScript = this.onChangeScript.bind(this);
        this.debounceOnChange = debounce((scriptNode) => this.props.onChange(scriptNode), 1000);
    }

    componentDidMount() {
        if (electron) {
            const mainWindow = remote.getCurrentWindow();
            mainWindow.on('close', () => {
                this.props.onUnlock();
            });
        }
        loader.config({
            monaco,
        });

        loader.config({
            'vs/nls': { availableLanguages: { '*': LocalesService.getLocale()?.toLowerCase() } },
        });
        setInterval(() => this.props.mode !== EditorMode.Read && this.props.onLock(), LOCK_TIMEOUT);
    }

    componentDidUpdate(prevProps: Readonly<TScriptEditorProps>, prevState: Readonly<any>) {
        const nextProps = this.props;
        const props = prevProps;
        const diffActions = changes(nextProps.actions, props.actions);

        if (diffActions.zoomFit) {
            props.onActionChange('zoomFit', false);
            props.onParamsChange('zoomLevel', 100);
        }
    }

    onChangeScript(value: string | undefined) {
        const { script } = this.state;
        script.src = value;
        this.setState({ script });

        this.debounceOnChange(script);
    }

    mapScriptNodeLanguageToCodemirrorMode(language: ScriptNodeLanguageEnum = 'JS') {
        const mapper = {
            GROOVY: 'kotlin',
            JS: 'javascript',
            GROOVY_DEBUG: 'kotlin',
            KOTLIN: 'kotlin',
            KOTLIN_DEBUG: 'kotlin',
        };

        return mapper[language];
    }

    render() {
        const { src, language } = this.state.script;
        const {
            script: { nodeId },
            commentsEnabledSchemesIds,
        } = this.props;
        const isShowCommentsPanel = commentsEnabledSchemesIds.some((id) => compareNodeIds(id, nodeId));
        const codemirrorMode = this.mapScriptNodeLanguageToCodemirrorMode(language);
        const readOnly = this.props.mode === EditorMode.Read;
        const comments = (
            <div className={isShowCommentsPanel ? theme.commentsPanel : theme.hideCommentsPanel}>
                <CommentsPanelContainer />
            </div>
        );

        return (
            <section className={theme.container}>
                {
                    //@ts-ignore
                    <SplitPane
                        split="vertical"
                        primary="second"
                        defaultSize={isShowCommentsPanel ? 280 : 0}
                        maxSize={isShowCommentsPanel ? 750 : 0}
                        minSize={isShowCommentsPanel ? 280 : 0}
                        paneStyle={{ overflow: 'auto' }}
                        resizerClassName={isShowCommentsPanel ? theme.resizer : theme.hideResizer}
                        pane2Style={{ borderLeft: '1px solid #d9d9d9' }}
                    >
                        <div
                            className={theme.editorContainer}
                            style={{ transform: `scale(${this.props.params.zoomLevel / 100})` }}
                            data-test="script-editor_container"
                        >
                            <Editor
                                className={theme.editor}
                                language={codemirrorMode}
                                value={src || ''}
                                onChange={(v, e) => this.onChangeScript(v)}
                                options={{
                                    formatOnType: true,
                                    readOnly,
                                    autoIndent: 'full',
                                    contextmenu: true,
                                    fontSize: 13,
                                    hideCursorInOverviewRuler: true,
                                    matchBrackets: 'always',
                                    minimap: {
                                        enabled: true,
                                    },
                                    showDeprecated: true,
                                }}
                            />
                        </div>
                        {comments}
                    </SplitPane>
                }
            </section>
        );
    }
}
const IntlComponent = injectIntl(ScriptEditor);

export { IntlComponent as ScriptEditor };
