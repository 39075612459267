import { BPMMxVertexHandler } from '@/mxgraph/handler/BPMMxVertexHandler.class';
import { MxCellState, MxConstants, MxRectangle, MxUtils } from 'MxGraph';

export class GridVertexHandler extends BPMMxVertexHandler {
    getSelectionBounds(state: MxCellState) {
        try {
            const target = this.graph.getLastEventTableTargetCell();
            const colIndex = this.graph.indexOfColumn(target);
            const rowIndex = this.graph.indexOfRow(target);
            const targetState = this.graph.view.getState(target);

            if (colIndex === 0 && rowIndex === 0) {
                return this.getStateRectangle(targetState);
            }

            if (colIndex === 0) {
                return this.getStateRectangle(state);
            }

            if (rowIndex === 0) {
                const { x, y, width } = targetState;
                const table = this.graph.getTable(target);
                const { height } = table.getGeometry();
                const style = this.graph.getCurrentCellStyle(table);
                const startSize = MxUtils.getNumber(style, MxConstants.STYLE_STARTSIZE, 0);

                return new MxRectangle(Math.round(x), Math.round(y), Math.round(width), Math.round(height - startSize));
            }

            return this.getStateRectangle(targetState);
        } catch (e) {
            return this.getStateRectangle(state);
        }
    }

    private getStateRectangle(state: MxCellState) {
        return new MxRectangle(
            Math.round(state.x),
            Math.round(state.y),
            Math.round(state.width),
            Math.round(state.height),
        );
    }
}
