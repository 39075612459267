import { defineMessages } from 'react-intl';

export default defineMessages({
    tabTitleAttributes: {
        id: 'NavigatorProperties.tabTitleAttributes',
        defaultMessage: 'Атрибуты',
    },
    tabTitleDefinitions: {
        id: 'NavigatorProperties.tabTitleDefinitions',
        defaultMessage: 'Декомпозиция',
    },
    tabTitleEdges: {
        id: 'NavigatorProperties.tabTitleEdges',
        defaultMessage: 'Связи',
    },
    decompositionParents: {
        id: 'NavigatorProperties.decompositionParents',
        defaultMessage: 'Элемент-родитель',
    },
    tabObjectInstances: {
        id: 'NavigatorProperties.tabObjectInstances',
        defaultMessage: 'Экземпляры',
    },
    tabColumnTitleName: {
        id: 'NavigatorProperties.tabColumnTitleName',
        defaultMessage: 'Название',
    },
    tabColumnTitleValue: {
        id: 'NavigatorProperties.tabColumnTitleValue',
        defaultMessage: 'Значение',
    },
    tabColumnObjectTitleName: {
        id: 'NavigatorProperties.tabColumnObjectTitleName',
        defaultMessage: 'Имя элемента',
    },
    tabColumnModelTitleName: {
        id: 'NavigatorProperties.tabColumnModelTitleName',
        defaultMessage: 'Имя модели',
    },
    defaultFolderType: {
        id: 'NavigatorProperties.defaultFolderType',
        defaultMessage: 'Стандартная папка',
    },
    tabTitleInstanceAttributes: {
        id: 'NavigatorProperties.tabTitleInstanceAttributes',
        defaultMessage: 'Атрибуты экземпляра',
    },
});
