import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import theme from './MultiLangInputDialog.scss';
import { useSelector } from 'react-redux';
import messages from './MultiLangInputDialog.messages';
import locales from '@/static/locales/index.json';
import footerMessages from '@/modules/Footer/FooterButtons.messages';
import { LANG } from '@/config/config';

import { OverflowTipInput } from './OverflowTipInput.component';
import cx from 'classnames';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { FieldErrors, FieldValues, Path, useForm, UseFormRegister } from 'react-hook-form';
import { InternationalString } from '@/serverapi/api';
import { TextArea } from '@/modules/UIKit/components/TextArea/TextArea.component';
import { Input } from '@/modules/UIKit/components/Input/Input.component';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { DialogFooterButtons } from '../../DialogFooterButtoms/DialogFooterButtons.component';

type TMultiLangInputDialogProps<T extends FieldValues> = {
    mainInputClassName?: string;
    formItemClassName?: string;
    placeholder?: string;
    name: Path<T>;
    value?: InternationalString;
    disabled?: boolean;
    maxLength?: number;
    textarea?: boolean;
    required?: boolean;
    label: string | JSX.Element;
    isAutoResize?: boolean;
    'data-test'?: string;
    containerDataTest?: string;
    autoFocus?: boolean;
    onChange: (multiLangValue: InternationalString) => void;
    isAgreement?: boolean;
    wrapperClassName?: string;
    errors: FieldErrors<T>;
    register: UseFormRegister<T>;
};

const MultiLangInputDialog = <T extends FieldValues>(props: TMultiLangInputDialogProps<T>) => {
    const {
        maxLength,
        disabled,
        placeholder,
        mainInputClassName,
        formItemClassName,
        textarea,
        value = { ru: '', en: '' },
        required,
        label,
        autoFocus,
        isAutoResize,
        containerDataTest,
        onChange,
        isAgreement,
        wrapperClassName,
        name,
        errors,
        register: mainFormRegister,
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();
    const {
        register,
        formState: { errors: modalErrors },
        trigger,
        reset,
        getValues,
    } = useForm({ defaultValues: value, mode: 'onSubmit' });

    const registerByLocale = (locale: keyof InternationalString) => {
        return register(locale, {
            validate: {
                whiteSpaces: (value) => !!value?.trim() || intl.formatMessage(messages.whitespacesOnly),
            },
            required: {
                value: !!required,
                message: intl.formatMessage(messages.requiredField),
            },
        });
    };

    const showModal = () => {
        reset(value);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        trigger().then((res) => {
            if (res) {
                setIsModalVisible(false);
                onChange(getValues());
            }
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const localesLabel = !disabled && (
        <span className={theme.openBtn} onClick={showModal}>
            (<u data-test="name-change-for-two-locales_btn">{currentLocale.toUpperCase()}</u>)
        </span>
    );

    const MainInputProps = {
        ...mainFormRegister(`${name}.${currentLocale}` as Path<T>, {
            onChange: (e) =>
                onChange({
                    ...value,
                    [currentLocale]: e.target.value.trim(),
                }),
            validate: {
                whiteSpaces: (value) => !!value?.trim() || intl.formatMessage(messages.whitespacesOnly),
            },
            required: {
                value: !!required,
                message: intl.formatMessage(messages.requiredField),
            },
        }),
        value: value[currentLocale],
        name: `${name}.${currentLocale}`,
        className: formItemClassName,
        classNameInput: mainInputClassName,
        placeholder,
        maxLength,
        autoFocus,
        isRequired: true,
        error: errors?.[name]?.[currentLocale]?.message,
        'data-test': props['data-test'],
        label: isAgreement ? (
            <div className={theme.labelWrapper}>
                {localesLabel}
                <div>{label}</div>
            </div>
        ) : (
            <div className={theme.labelWrapper}>
                <span>{label}</span>
                {localesLabel}
            </div>
        ),
    };
    const AutoSizeProps = {
        isAutoResize,
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'back',
                    onClick: handleCancel,
                    value: intl.formatMessage(footerMessages.cancel),
                    dataTest: 'translation-window-cancel_btn',
                },
                {
                    key: 'submit',
                    onClick: handleOk,
                    value: intl.formatMessage(footerMessages.save),
                    visualStyle: 'primary',
                    dataTest: 'translation-window-ok_btn',
                },
            ]}
        />
    );

    return (
        <div data-test={containerDataTest} className={cx(wrapperClassName, theme.container)}>
            {textarea ? (
                <TextArea {...MainInputProps} {...AutoSizeProps} />
            ) : (
                <OverflowTipInput
                    {...MainInputProps}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') e.preventDefault();
                    }}
                />
            )}

            <Dialog
                title={intl.formatMessage(messages.modalTitle)}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={footer}
                className={theme.dialog}
            >
                <div className={theme.dialog__body}>
                    {locales.map((locale: keyof InternationalString) => {
                        const LocaleInputProps = {
                            ...registerByLocale(locale),
                            placeholder,
                            label: LANG[locale],
                            maxLength,
                            error: modalErrors?.[locale]?.message,
                            'data-test': `${props['data-test']}_${locale}`,
                            key: locale,
                            classNameInput: theme.input,
                            classNameTextArea: theme.textArea,
                        };

                        return textarea ? (
                            <TextArea className={theme.textArea} {...LocaleInputProps} {...AutoSizeProps} />
                        ) : (
                            <Input className={theme.input} {...LocaleInputProps} />
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};

export { MultiLangInputDialog };
